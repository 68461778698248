<template>
  <div
    style="margin-top: 0px; z-index: 2"
    @click="removeLists"
  >
    <div>
      <div
        class="table__header"
        style="margin-bottom: 15px !important; justify-content: space-between"
      >
        <div class="filtering">
          <div style="display:flex;">
            <div
              class="search-toggle"
            >
              <p>{{ $t('SearchBy') }}</p>
              <div>
                <p :class="{ active2: toggleSarch }">
                  {{ $t('Color_Namee') }}
                </p>
              </div>
            </div>
            <div>
              <div class="black">
                <b-icon-search
                  v-if="searchColor == ''"
                  class="search1"
                  style="position: absolute; left:5px; top: 12px"
                />
                <b-icon-x-circle
                  v-else-if="searchColor != '' && !noSearch"
                  class="search1"
                  style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                  @click="searchColor = ''"
                />
                <input
                  v-model="searchColor"
                  autocomplete="off"
                  type="text"
                  name="search-box"
                  :placeholder="`${$t('Color_Name')} `"
                  :style="getColorLikeName.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
                  @keyup="inputChanged"
                  @keydown.down="onArrowDown"
                  @keydown.up="onArrowUp"
                >
              </div>
              <div
                v-if="getColorLikeName.length > 0 && noSearch"
                ref="scrollContainer"
                class="dropdrop"
                :style="getColorLikeName.length > 7 ? 'height: 210px' : 'height: auto'"
              >
                <div
                  v-for="(result, index) in getColorLikeName"
                  :key="index"
                  ref="options"
                  style="cursor: pointer"
                  class="search-item"
                  :class="{ 'is-active': index === arrowCounter }"
                  @click="searchByOn(result); noSearch = false"
                >
                  <p style="margin: 0">
                    {{ result.colorName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <button
          v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
          v-b-modal.modal-new-color
          class="button"
          style="margin-top: 7px;"
        >
          <img
            src="../assets/images/plus-circle.svg"
            alt="plus"
          >
          {{ $t("AddNewColor") }}
        </button>
      </div>
      <div style="width: 100%; padding-top: 0px; margin-top: 0px; height: 80vh; overflow-y: scroll;">
        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead
            style="cursor:auto; position: sticky; top: -1px;"
          >
            <tr>
              <th>
                {{ $t('No') }}
              </th>
              <th>
                {{ $t('Color_Name') }}
              </th>
              <th>
                {{ $t('Visual_Color') }}
              </th>
              <th>
                {{ $t('Hash_Color') }}
              </th>

              <th
                v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
              >
                {{ $t('Edit') }}
              </th>
            </tr>
          </thead>
          <tbody v-if="getColorDataPagination.length > 0">
            <tr
              v-for="(order,index) in getColorDataPagination"
              :key="order.id"
              style="cursor:auto"
            >
              <td>
                {{ getTotalItemsForColors-(page-1)*pageSize-index }}.
              </td>
              <td>
                {{ order.colorName }}
              </td>
              <td>
                <div style="display: flex; margin-left: 30px;">
                  <span
                    class="colored-circle"
                    :style="`background-color: ${order.hashColor}; margin-right: 5px; box-shadow: none;`"
                  />
                </div>
              </td>
              <td>
                <div style="margin-left: 10px">
                  {{ order.hashColor }}
                </div>
              </td>
              <td
                v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
                id="edit"
                style="text-align: left; cursor: pointer;"
                @click="editC(order)"
              >
                <p
                  v-b-modal.modal-edit-color
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil />
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                colspan="5"
                style="text-align: left;"
              >
                {{ $t('No_Colors_are_shown') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-if="getTotalItemsForColors > 15"
          v-model="page"
          :total-rows="getTotalItemsForColors"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <AddNewColorVue @addNewColor="addCol" />
    <EditColorModal
      :color-data="color"
      @editColor="editCol"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddNewColorVue from '@/modals/AddNewColor.vue';
import EditColorModal from '../modals/EditColorModal.vue'

export default {
  components: {
    AddNewColorVue,
    EditColorModal,
  },
  data() {
    return {
      toggleSarch: false,
      noSearch: false,
      searchColor: '',
      page: 1,
      arrowCounter: 0,
      enterPressed: false,
      colorName: '',
      colorId: null,
      pageSize: 15,
      color: {
        colorDataId: '',
        colorName: '',
        hashColor: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getColorsData', 'getLoggedInUser', 'getCurrentPageColors', 'getTotalItemsForColors', 'getColorLikeName', 'getColorDataPagination']),
  },
  watch: {
    page(value) {
      this.colorsPagination({
        colorId: this.colorId,
        pageNumber: value,
        pageSize: this.pageSize,
        successCallback: () => { },
      });
    },
    searchColor(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.colorsPagination({ colorId: null, pageNumber: 1, pageSize: this.pageSize });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.colorDataLike(value);
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    this.colorsPagination({ colorId: null, pageNumber: 1, pageSize: this.pageSize })
  },
  methods: {
    ...mapActions([
      'changeLoadingtoTrue',
      'addColor', 'colorDataLike', 'resetColorNames', 'editColor', 'colorsPagination']),
    async searchByOn(value) {
      this.searchColor = value.colorName
      // this.noSearch = false
      await this.colorsPagination({
        colorId: value.colorId,
        pageNumber: this.page,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.$router.push('/order-item-tracking')
        },
      })
      // this.checkIfArticleNumWasNull()
    },
    async addCol(obj) {
      await this.addColor({
        object: obj,
        successCallback: () => {
          this.colorsPagination({ colorId: null, pageNumber: 1, pageSize: this.pageSize });
          this.page = 1
        },
      });
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)
        await this.colorsPagination({
          colorId: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        this.changeLoadingtoTrue(false)
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetColorNames();
    },
    editC(item) {
      this.color.colorDataId = item.id;
      this.color.colorName = item.colorName;
      this.color.hashColor = item.hashColor
    },
    // checkIfArticleNumWasNull() {
    //   // eslint-disable-next-line no-unused-vars
    //   for (let i = 0; i < this.getOrderTrackItems.length; i++) {
    //     if (this.getOrderTrackItems[0].orderItemId != this.getOrderTrackItems[i].orderItemId) {
    //       // eslint-disable-next-line no-const-assign
    //       this.wasOrNot = true
    //     }
    //   }
    //   return this.wasOrNot
    // },

    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getColorsData.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getColorsData = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getColorLikeName[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async editCol(obj) {
      await this.editColor({
        colorId: obj.colorDataId,
        object: obj,
        successCallback: () => {
          this.colorsPagination({ colorId: null, pageNumber: this.getCurrentPageColors, pageSize: this.pageSize });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dropdrop::-webkit-scrollbar {
  display: none;

}

.team_table td {
  padding: 16px 9px !important;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

table:hover {
  cursor: pointer;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
