<template>
  <div>
    <b-modal
      id="modal-edit-color"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('Edit')} `"
      hide-footer
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('ColorName')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.colorName.$model"
            aria-describedby="input-1-live-feedback"
            :state="validateState('colorName')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="colorContainer">
          <b-form-group
            id="input-group-1"
            :label="`${$t('Hash_Colorr')}:`"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="$v.form.hashColor.$model"
              :state="validateState('hashColor')"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_7_characters') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-for="input-2"
            :label="`${$t('Color_Pick')}:`"
          >
            <b-form-input
              id="input-2"
              v-model="$v.form.hashColor.$model"
              type="color"
              :state="validateState('hashColor')"
              aria-describedby="input-1-live-feedback"
            />
            <!-- <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback> -->
          </b-form-group>
        </div>


      </b-form>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';


export default {
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['colorData'],

  data() {
    return {
      page: 1,
      pageP: 1,
      pageSize: 15,
      totalItems: '',
      options: [],
      selected: null,
      colors: [],
      form: {},
    }
  },
  validations: {
    form: {
      colorName: {
        required,
        minLength: minLength(3),
      },
      hashColor: {
        required,
        minLength: minLength(7),
      },
    },
  },
  computed: {
    // ...mapGetters(['']),
  },
  mounted() {
    this.form = this.colorData
  },
  methods: {
    // ...mapActions(['']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('editColor', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },

}
</script>

<style lang="scss" scoped>
.colorContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
