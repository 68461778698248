<template>
  <div >
    <!-- <Navbar />
    <Sidebar /> -->
    <section class="main no-flex">

      <ColorsTable :material-id="materialIdToShow" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/sidebars/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
// import SupplierTable from '@/components/SupplierTable.vue'
import ColorsTable from '@/components/ColorsTable.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    // SupplierTable,
    ColorsTable,
  },
  data() {
    return {
      materialIdToShow: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1500px){
  .main{
    padding: 70px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
